#logo {
  font-size: 30px;
  letter-spacing: 2px;
  text-decoration: none !important;

}

.containerHead {
  max-width: initial !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: white !important;
}

#MarqueeCss {
  color: white;
  font-size: 18px;
  font-weight: 600 !important;
}

.dropdown-toggle {
  background-color: #232F3E !important;
  border: 1px solid #232F3E !important;
}



@media(max-width:767px) {
  #logo {

    width: 79% !important;

  }
}

@media(max-width:800px) {
  #CLinicLogo1 {
    margin-left: 20px !important;

  }
}

#CLinicLogo {
  width: 18%;
}

/* @media(max-width:800px) {
  #CLinicLogo1 {
    width: 50% !important;
  }
} */

#CLinicLogo1 {
  /* width: 140px; */
  margin-left: 240px;
  height: 50px !important;
}

#MainNavbarCssbgcolor {
  background-color: #232F3E !important;
}

.fontheader {
  font-size: 14px;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  font-weight: 550;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #232F3E !important;
  color: white !important;
}

#basic-nav-dropdown1 .dropdown-item:hover {
  background-color: #232F3E !important;
  color: white !important;
}

.dropdownbutton .btn .btn-primary {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.btn-primary:focus {
  box-shadow: none !important;
}

#dropdown-menu-align-end {
  font-size: 14px;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  font-weight: 550;
  border: 1px solid white !important;
  color: white !important;
}

.dropdown .dropdown-menu .dropdown-item:hover{
   background-color: #f2f4f9 !important;
  }

  .dropdown .dropdown-menu .dropdown-item:hover, .dropdown .dropdown-menu .dropdown-item:hover i, .dropdown .dropdown-menu .dropdown-item:hover svg, .btn-group .dropdown-menu .dropdown-item:hover, .fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group .dropdown-menu .dropdown-item:hover, .fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group .dropdown-menu .dropdown-item:hover, .btn-group .dropdown-menu .dropdown-item:hover i, .fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group .dropdown-menu .dropdown-item:hover i, .fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group .dropdown-menu .dropdown-item:hover i, .btn-group .dropdown-menu .dropdown-item:hover svg, .fc .fc-toolbar.fc-header-toolbar .fc-left .fc-button-group .dropdown-menu .dropdown-item:hover svg, .fc .fc-toolbar.fc-header-toolbar .fc-right .fc-button-group .dropdown-menu .dropdown-item:hover svg {
  color: #0088ff !important;
  }

  #refreshname:hover{
    color: #0088ff !important;
  }
