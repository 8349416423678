

/* 
.spinner {
    
  position: absolute;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
 
}
.ball {
  height: 50px;
  width: 50px;
  left: 50%;
  top: 50%;
  background: rgba( 0, 255, 0, .5);
  border-radius: 50%;
  float: left;
  position: absolute;
}

.ball-1 {
  background: rgba( 255, 0, 0, .5);
  top: 0;
  left: 50%;
  animation: ball1 1s 0s ease infinite;
  z-index: 1;
}
.ball-2 {
  background: rgba( 0, 255, 0, .5);
  top: 50%;
  left: 100%;
  animation: ball2 1s 0s ease infinite;
  z-index: 2;
}

.ball-3 {
  background: rgba( 0, 0, 255, .5);
  top: 100%;
  left: 50%;
  animation: ball3 1s 0s ease infinite;
  z-index: 1;
}

.ball-4 {
  background: rgba( 255, 255, 0, .5);
  top: 50%;
  left: 0%;
  animation: ball4 1s 0s ease infinite;
  z-index: 2;
}

@keyframes ball1 {
  50% {
    top: -100%;
    left: 200%;
    background: rgba( 127, 127, 0, .5);
  }
  100% {
    top: 50%;
    left: 100%;
    background: rgba( 0, 255, 0, .5);
    z-index: 2;
  }
}

@keyframes ball2 {
  50% {
    top: 200%;
    left: 200%;
    background: rgba( 0, 255, 255, .5);
  }
  100% {
    top: 100%;
    left: 50%;
    background: rgba( 0, 0, 255, .5);
    z-index: 1;
  }
}

@keyframes ball3 {
  50% {
    top: 200%;
    left: -100%;
    background: rgba( 255, 0, 255, .5);
  }
  100% {
    top: 50%;
    left: 0%;
    background: rgba( 255, 255, 0, .5);
    z-index: 2;
  }
}

@keyframes ball4 {
  50% {
    top: -100%;
    left: -100%;
    background: rgba( 255, 127, 0, .5);
  }
  100% {
    top: 0%;
    left: 50%;
    background: rgba( 255, 0, 0, .5);
    z-index: 1;
  }
} */

/*************************  New Loader *****************************/
.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(248, 244, 244, 0.749);
  z-index: 1000; /* Adjust the z-index value as needed */
}


.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}