/* #form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
} */
.scrollit {
  overflow:scroll;
  height:400px;
}
.dropzone {
  height: 200px;
  display: table;
  width: 100%;
  background-color:whitesmoke;
  border: dotted 1px rgb(43, 39, 62);
}


input[type='file'] {
    
  padding: 140px 50px 10px 50px;
  cursor: move;
  position:relative;
  min-height: 150px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  background: white;  
  width: 100%;
}
input[type='file']:before {
content: "Drag & Drop your files here";
display: block;
position: absolute;
text-align: center;
top: 50%;
left: 50%;
width: 200px;
height: 40px;
margin: -25px 0 0 -100px;
font-size: 18px;
font-weight: bold;
color: #999;
}

.ScrollDocumnentdata {
  width: 100% !important;
  height: 300px !important;
  overflow: scroll;
}

table {
  text-align: justify !important;
}

#Namecsstable{
  padding-left: 10px !important;
  width:70% !important;
}

#Datecsstable{
  padding-left: 10px !important;
}

#colorcss{
  color: #0dcaf0!important;
    cursor: pointer;
}

.theadcss{
  color: #495057 !important;
  background-color: #e9ecef !important;
  border-color: #dee2e6 !important;
  border: 1px solid !important;
}

#searchfordoc{
  border-radius: 0px !important;
}

#newfolderbtn{
  text-align: end !important;
}
.buttoncolor{
background-color: #4ca7f6;

}

#Modalbodydocument{
  height: auto!important;
}

